import { CompanyInvitationRole, Role } from '__generated__/GQL';

import { type RootLoggedInRoutesAccessFragment } from '../graphql/rootCompany.gql';
import { type GetLoggedInRoutesAccessQuery } from './graphql/queries/getLoggedInRoutesAccess.gql';

const LOCKED = 'LOCKED';
const VALIDATED = 'VALIDATED';

// kycStatus is the result of the last KyCReview
// a user can only have access to their account if:
// (a) their company is validated and
// (b) their account is validated
// essentially allowing beneficiaries to be added to
// a validated account and then following the onboarding process
// note: companyUsers should only return the currently logged user
const getIsAccountValidated = (
  data: GetLoggedInRoutesAccessQuery | undefined,
): boolean => {
  return (
    (data?.viewer.companyUser.isOnboarded ?? false) &&
    data?.viewer.company.metadata.accountStatus === VALIDATED
  );
};

const getIsAccountLocked = (
  data: GetLoggedInRoutesAccessQuery | undefined,
): boolean => {
  return data?.viewer.company.metadata.status === LOCKED;
};

const getIsBeneficiaryWithNoAccess = (
  data: GetLoggedInRoutesAccessQuery | undefined,
): boolean =>
  data?.viewer.companyUser.isLegalBeneficiary === true &&
  data?.viewer.companyUser.isPrimaryApplicant === false &&
  data?.viewer.companyUser.roles.includes(Role.Invitee) &&
  getIsAccountValidated(data);

const getHasInvitationRole = (
  data: GetLoggedInRoutesAccessQuery | undefined,
): boolean => {
  const invitationRole = data?.viewer.companyUser.invitation?.role;
  return (
    invitationRole === CompanyInvitationRole.Accountant ||
    invitationRole === CompanyInvitationRole.Employee ||
    invitationRole === CompanyInvitationRole.Admin
  );
};

const getLoggedInRoutesAccess = (
  data: RootLoggedInRoutesAccessFragment | undefined,
) => {
  const isAccountValidated = getIsAccountValidated(data);
  const isAccountLocked = getIsAccountLocked(data);
  const isBeneficiaryWithNoAccess = getIsBeneficiaryWithNoAccess(data);
  const hasInvitationRole = getHasInvitationRole(data);

  return {
    companyProfileId: data?.viewer.company.companyProfileId,
    companyUserId: data?.viewer.companyUser.companyUserId,
    hasInvitationRole,
    invitationRole: data?.viewer.companyUser.invitation?.role,
    isAccountLocked,
    isAccountValidated,
    isBeneficiaryWithNoAccess,
    isBetaTester: data?.viewer.companyUser.isBetaTester,
    isNewOnboardingAllowed: data?.viewer.isNewOnboardingAllowed,
    isOnboarded: data?.viewer.companyUser.isOnboarded,
    kycStatus: data?.viewer.companyUser.kycStatus,
    shouldAskForEpCguAgreement:
      data?.viewer.companyUser.shouldAskForEpCguAgreement &&
      /**
       * admins should not have be asked about us citizenship and therefore
       * we need to exclude them here
       * @todo shouldAskForEpCguAgreement should be updated so that we can
       * remove this condition
       * @see https://linear.app/shine/issue/CPA-1357/update-isprimaryapplicant-logic-in-the-backend-to-remove-dirty-check
       */
      data?.viewer.companyUser.invitation?.role !== CompanyInvitationRole.Admin,
  };
};

export default getLoggedInRoutesAccess;
