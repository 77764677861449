import LocalizedStrings from 'localized-strings';

const locales = new LocalizedStrings({
  en: {
    form: {
      emailAlreadyExistsError: 'This email address is already used',
      labelCgu: 'I agree to the',
      labelCguLink: 'terms and conditions',
      labelEmail: 'Email address',
      labelFirstName: 'First name',
      labelLastName: 'Last name',
      labelLegalTitle: 'Legal title',
    },

    page: {
      cta: 'Continue',
      hasReferral: '**{firstName}** offers you up to 2 months free!',
      shouldHaveReferral: 'I have a referral',
      title: 'Let’s talk about you',
    },
  },

  fr: {
    form: {
      emailAlreadyExistsError: 'Cette adresse email est déjà utilisée',
      labelCgu: 'J’accepte les',
      labelCguLink: 'conditions générales d’utilisation',
      labelEmail: 'E-mail',
      labelFirstName: 'Prénom',
      labelLastName: 'Nom de famille',
      labelLegalTitle: 'Civilité',
    },

    page: {
      cta: 'Continuer',
      hasReferral: '**{firstName}** vous offre jusqu’à 2 mois gratuits\u00a0!',
      shouldHaveReferral: 'J’ai été parrainé·e',
      title: 'Faisons d’abord connaissance',
    },
  },
} as const);

export default locales;
