/* eslint-disable jsx-a11y/no-autofocus */

import { type FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import Button from 'components/_core/Button';
import * as Form from 'components/_core/form';
import Column from 'components/Column';
import LegacyForm from 'components/Form';
import formLocales from 'features/Bento/libs/locales';
import AlreadyUsedAlert from 'features/Onboarding/components/AlreadyUsedAlert/AlreadyUsedAlert';
import { checkEmail, startRegister } from 'helpers/auth/service';

import BackButton from '../components/BackButton';
import locales from './locales';
import { Subtitle, Title } from './styles';
import { type CreateEmailProps } from './types';

export const emailSchema = z.object({
  email: z.string().email({
    message: formLocales.errors.customRequiredFields.email,
  }),
});

const CreateEmail: FC<CreateEmailProps> = ({
  email,
  hasAgreedToTermsOfService,
  onBack,
  phone,
  setCurrentStep,
  setEmail,
  toggleHasAgreedToTermsOfService,
}) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: {
      email,
    },
    mode: 'onSubmit',
    resolver: zodResolver(emailSchema),
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = handleSubmit(async (values) => {
    // TODO(Achille): This should be done with an async validator
    // on the input component itself
    try {
      setIsLoading(true);
      const { available } = await checkEmail(values.email);
      setIsLoading(false);

      if (!available) {
        setError('email', {
          type: 'already-used',
        });
      }

      const isValid = available && hasAgreedToTermsOfService;

      if (isValid) {
        setEmail(values.email);
        await startRegister({ phone });
        setCurrentStep('createVerificationCode');
      }
    } catch (error) {
      setIsLoading(false);
    }
  });

  return (
    <LegacyForm onValidSubmit={onSubmit}>
      <BackButton onClick={onBack} />
      <Column>
        <Title variant="primary">{locales.title}</Title>
        <Subtitle>{locales.subtitle}</Subtitle>
        <Column>
          <Form.Field error={errors.email?.message} label={locales.inputLabel}>
            <Form.Input {...register('email')} autoFocus type="email" />
          </Form.Field>
          {errors.email?.type === 'already-used' ? (
            <AlreadyUsedAlert type="email" />
          ) : null}
        </Column>

        <Form.Checkbox
          isChecked={hasAgreedToTermsOfService}
          marginTop="space-48"
          onChange={toggleHasAgreedToTermsOfService}
        >
          <Form.Checkbox.Label>
            {locales.agreement}&nbsp;
            <Link href="https://www.shine.fr/lp/cgu-ep-en-clair/" isExternal>
              {locales.termsAndConditions}
            </Link>
          </Form.Checkbox.Label>
        </Form.Checkbox>

        <Button
          isDisabled={!hasAgreedToTermsOfService}
          isLoading={isLoading}
          marginTop="space-32"
          type="submit"
        >
          {locales.cta}
        </Button>
      </Column>
    </LegacyForm>
  );
};

export default CreateEmail;
