import { Code } from '@chakra-ui/react';

import { type FCWithChildren } from 'common/types';

/**
 * Displays code or JSON data in a nice way
 */
const DevCode: FCWithChildren = ({ children }) => (
  <Code
    as="pre"
    display="block"
    fontSize="font-12"
    overflow="auto"
    padding="space-8"
    textAlign="left"
  >
    {children}
  </Code>
);

export default DevCode;
