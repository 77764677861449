import { Box, ButtonGroup, Kbd, Text } from '@chakra-ui/react';

import asBentoPage from 'common/bento/hoc/asBentoPage';
import stringifyState from 'common/bento/lib/stringifyState';
import { type AnyState } from 'common/bento/types/Abstract';
import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';

import DevCode from './components/DevCode';

interface BentoDevPageProps {
  moduleData: unknown;
  state: AnyState;
  onNext: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  send: (event: any) => void;
}

/**
 * A dev-only page that displays the current state of a given machine.
 * Allows you to send available events to the machine, to simulate navigation.
 */
const DevPage = asBentoPage<BentoDevPageProps>(
  ({ Layout, moduleData, send, state }) => {
    const nextEvents = state.nextEvents.filter(
      (event) => event.length && event !== '*',
    );

    const stringifiedState = stringifyState(state);

    return (
      <Layout>
        <Box>
          <Box>
            <Heading marginBottom="space-16">
              Dev Page: <Kbd color="grey.-2">{stringifiedState}</Kbd>
            </Heading>

            <Text>
              You are now in state <Kbd color="grey.-2">{stringifiedState}</Kbd>
            </Text>
          </Box>

          <Box>
            <Heading marginBottom="space-4" size="sm">
              Module Data
            </Heading>
            <DevCode>{JSON.stringify(moduleData, null, 2)}</DevCode>
          </Box>

          <Box>
            <Heading marginBottom="space-4" size="sm">
              Machine Context
            </Heading>
            <DevCode>{JSON.stringify(state.context, null, 2)}</DevCode>
          </Box>

          <Box>
            <Heading marginBottom="space-4" size="sm">
              Send event
            </Heading>
            <ButtonGroup spacing="space-8">
              {nextEvents.map((event) => (
                <Button key={event} onClick={() => send(event)} size="sm">
                  {event}
                </Button>
              ))}
            </ButtonGroup>
          </Box>
        </Box>
      </Layout>
    );
  },
);

export default DevPage;
