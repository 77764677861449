import { type FC } from 'react';
import { useHistory } from 'react-router';

import { type RoleFeaturesProps } from 'features/TeamOnboarding/TeamOnboardingTutorials/utils';

import Layout from '../../layouts/FeaturesLayout';
import Expenses from './components/Expenses';
import PaymentCards from './components/PaymentCards';
import Receipts from './components/Receipts';
import locales from './locales';

const EmployeeFeatures: FC<RoleFeaturesProps> = ({ onTutorialsDone }) => {
  const history = useHistory();
  const handleOnDone = async () => {
    await onTutorialsDone();
    history.push('/cards/request-new');
  };

  return (
    <Layout
      carouselHeight="28.75rem" // 460px
      getCtaText={({ isLast }) => (isLast ? locales.lastFeatureCta : null)}
      onDone={handleOnDone}
    >
      <Expenses key="expenses" />
      <Receipts key="receipts" />
      <PaymentCards key="cards" onTutorialsDone={onTutorialsDone} />
    </Layout>
  );
};

export default EmployeeFeatures;
