import { createMachine } from 'xstate';
import { pure } from 'xstate/lib/actions';

import shared from 'common/bento/shared';
import { identify, logEvent } from 'features/Analytics/analytics';
import getUTMParametersFromStorage from 'features/Analytics/helpers/getUTMParametersFromStorage';
import { type AnyMachineEvent } from 'features/Bento/types/Abstract';
import { type MachineModelFrom } from 'features/Bento/types/Machine';

import actions from './actions';
import guards from './guards';
import model, { type Context } from './model';
import services from './services';

export enum State {
  FORM = 'form',
  REFERRAL_GUIDE = 'referral-guide',
  PHONE = 'phone',
  PASSCODE = 'passcode',
  PASSCODE_CONFIRM = 'passcode-confirm',
  PASSCODE_CONFIRM_INVALID = 'passcode-confirm-invalid',
  VERIFICATION_CODE = 'verification-code',
  VERIFICATION_CODE_RESEND = 'verification-code-resend',
  CHECK_VERIFICATION_CODE = 'check-verification-code',
  ACCOUNT_ALREADY_EXISTS = 'account-already-exists',
  START_REGISTER = 'start-register',
  CREATE_ACCOUNT_AND_LOGIN = 'create-account-and-login',
  DONE = 'done',
  ABORT = 'abort',
}

const machine = createMachine<Context, AnyMachineEvent>(
  {
    context: model.initialContext,

    id: 'account-creation',

    initial: State.FORM,

    on: {
      '*': {
        actions: shared.actions.unhandled(),
      },
    },

    states: {
      [State.FORM]: {
        entry: shared.actions.trackView(),

        on: {
          GO_REFERRAL_GUIDE: State.REFERRAL_GUIDE,
          PREV: State.ABORT,
          SUBMIT_FORM_DATA: {
            actions: [
              'assignFormData',
              pure((context) =>
                shared.actions.identify({
                  email: context.form.email,
                  firstName: context.form.firstName,
                  gender: context.form.gender,
                  lastName: context.form.lastName,
                  signupCompanyProfileType: context.companyProfileType,
                }),
              ),
            ],
            target: State.PHONE,
          },
        },
      },

      [State.REFERRAL_GUIDE]: {
        on: {
          PREV: State.FORM,
        },
      },

      [State.PHONE]: {
        entry: [shared.actions.trackView(), 'clearPasscodeAndVerificationCode'],

        on: {
          PREV: State.FORM,
          SUBMIT_PHONE: {
            actions: ['assignPhone', 'triggerMarketingZap'],
            target: State.START_REGISTER,
          },
        },
      },

      [State.PASSCODE]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: State.PHONE,
          SUBMIT_PASSCODE: {
            actions: 'assignPasscode',
            target: State.PASSCODE_CONFIRM,
          },
        },
      },

      [State.PASSCODE_CONFIRM]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: State.PASSCODE,
          SUBMIT_PASSCODE: [
            {
              cond: 'isPasscodeIdentical',
              target: State.CREATE_ACCOUNT_AND_LOGIN,
            },
            State.PASSCODE_CONFIRM_INVALID,
          ],
        },
      },

      [State.PASSCODE_CONFIRM_INVALID]: {
        entry: shared.actions.trackView(),

        on: {
          PREV: State.PHONE,
          SUBMIT_PASSCODE: {
            actions: 'assignPasscode',
            target: State.PASSCODE_CONFIRM,
          },
        },
      },

      [State.START_REGISTER]: {
        invoke: {
          onDone: {
            actions: ['assignVerificationCodeTimestamp'],
            target: State.VERIFICATION_CODE,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.PHONE,
          },
          src: 'startRegister',
        },
      },

      [State.VERIFICATION_CODE]: {
        entry: shared.actions.trackView(),

        on: {
          ASK_NEW_VERIFICATION_CODE: State.VERIFICATION_CODE_RESEND,
          PREV: State.PHONE,
          SUBMIT_VERIFICATION_CODE: State.CHECK_VERIFICATION_CODE,
        },
      },

      [State.CHECK_VERIFICATION_CODE]: {
        invoke: {
          onDone: [
            {
              actions: 'assignPhoneNumberOwnershipToken',
              cond: 'isPhoneAvailable',
              target: State.PASSCODE,
            },
            {
              target: State.ACCOUNT_ALREADY_EXISTS,
            },
          ],
          onError: [
            {
              actions: 'assignVerificationCodeError',
              cond: 'isVerificationCodeError',
              target: State.VERIFICATION_CODE,
            },
            {
              actions: shared.actions.error(),
              target: State.VERIFICATION_CODE,
            },
          ],
          src: 'checkVerificationCode',
        },
      },

      /**
       * This is a terminal state.
       * Users arriving here are invited to use a link to go back to login.
       */
      [State.ACCOUNT_ALREADY_EXISTS]: {
        on: {},
      },

      [State.VERIFICATION_CODE_RESEND]: {
        invoke: {
          onDone: {
            actions: ['assignVerificationCodeTimestamp'],
            target: State.VERIFICATION_CODE,
          },
          onError: {
            actions: shared.actions.error(),
            target: State.VERIFICATION_CODE,
          },
          src: 'startRegister',
        },
      },

      [State.CREATE_ACCOUNT_AND_LOGIN]: {
        invoke: {
          onDone: {
            actions: [
              pure(() => shared.actions.alias()), // We need to alias the user before the first identify
              pure((context) =>
                shared.actions.identify({
                  phone: context.phoneNumber,
                }),
              ),
              () => {
                // eslint-disable-next-line @typescript-eslint/naming-convention
                const { utm_campaign, utm_medium, utm_source } =
                  getUTMParametersFromStorage();

                identify({
                  'utm_campaign [last touch]': utm_campaign,
                  'utm_medium [last touch]': utm_medium,
                  'utm_source [last touch]': utm_source,
                });

                logEvent({
                  context: {
                    campaign: {
                      medium: utm_medium,
                      name: utm_campaign,
                      source: utm_source,
                    },
                  },
                  name: 'Signup Completed',
                  usePrefix: false,
                });
              },
            ],
            target: State.DONE,
          },
          onError: [
            {
              actions: shared.actions.error(),
              target: State.PHONE,
            },
          ],
          src: 'createAccountAndLogin',
        },
      },

      [State.DONE]: {
        /**
         * The context of this machine will be used by the parent, once the account is created,
         * to trigger the appropriate marketing webhooks.
         */
        data: (context) => context,
        type: 'final',
      },

      [State.ABORT]: {
        data: () => 'ABORT',
        type: 'final',
      },
    },
  },

  {
    actions,
    guards,
    services,
  },
);

export default machine;

export type Intro = MachineModelFrom<typeof machine, typeof model>;
