import { type FC } from 'react';
import { Box, chakra, Text } from '@chakra-ui/react';

import Button from 'components/_core/Button';
import Heading from 'components/_core/Heading';

import locales from './locales';

const AccountAlreadyExists: FC<{
  onClickLogin: () => void;
}> = ({ onClickLogin }) => {
  return (
    <Box>
      <Heading marginBottom="space-24" size="lg">
        {locales.title}
        <chakra.span color="orange.600"> {locales.titleSuffixA}</chakra.span>
        {locales.titleSuffixB}
      </Heading>

      <Text marginBottom="space-24">{locales.description}</Text>

      <Button alignSelf="flex-start" onClick={onClickLogin} variant="primary">
        {locales.login}
      </Button>
    </Box>
  );
};

export default AccountAlreadyExists;
